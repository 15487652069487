export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyAXUUyQ3QZIMnaqU_n83rpLwZ2b3ivV7H8",
    authDomain: "essilor-prd.firebaseapp.com",
    databaseURL: "https://essilor-prd.firebaseio.com",
    projectId: "essilor-prd",
    storageBucket: "essilor-prd.appspot.com",
    messagingSenderId: "267561288269",
    appId: "1:267561288269:web:ee6c43916fa753c9"

    // apiKey: "AIzaSyBYo_mD6OM45m8iSvsVoFvmc0xfRak0HFw",
    // authDomain: "essilor-conecta-dev.firebaseapp.com",
    // databaseURL: "https://essilor-conecta-dev.firebaseio.com",
    // projectId: "essilor-conecta-dev",
    // storageBucket: "essilor-conecta-dev.appspot.com",
    // messagingSenderId: "743556662365",
    // appId: "1:743556662365:web:fe83a712e35c34b6"
  },
  // createUserAPIEndpoint: 'https://us-central1-varilux-especialista-dev.cloudfunctions.net/api',
  engineEndpoint: 'https://us-central1-iloyalty-prd.cloudfunctions.net/engine',
  missionEndpoint: 'https://us-central1-iloyalty-prd.cloudfunctions.net/userService',
  catalogEndpoint: 'https://us-central1-iloyalty-prd.cloudfunctions.net/api',
  campaignId: 'ee4d7a9e-7b89-4bb7-a792-1b97891bbd8b',
  campaignName: 'conecta',
  segmentSilver: '01337e66-5088-40f5-bfff-de1e45acd5e7',
  segmentGold: 'fbc8faa7-9ede-4afd-8f82-89d6f93bf9ee',
  segmentDiamond: '50da5c32-8e19-451a-b7be-cc38d25bc4d7',
  secToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb21wYW55IjoiTU0xMiIsInBhc3N3b3JkIjoic2lkdXNAMjAxOSIsImlhdCI6MTU2MDU1MjU3MCwiZXhwIjoxNTYzMTQ0NTcwfQ.2FyO6h9vZg3WrzAoIMSexdHcPn4wY7f-vpvOLECKXZI',
  version: '1.0.10',
  envMotor: '',
  endpointCatalogo: 'https://catalogomm12.com.br:9909/',
  // Conectados
  conectadosMatchmakingServer: 'http://107.20.178.57:3774',
  conectadosGameServer: 'http://107.20.178.57:3994'
  // conectadosMatchmakingServer: 'localhost:3774',
  // conectadosGameServer: 'localhost:3994'
};
