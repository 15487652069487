import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient, private auth: AngularFireAuth) { }

  getTargetsByMonth(month, cnpj, year) {
      return new Promise((resolve, reject) => {
          const url = `${environment.engineEndpoint}/v1/targets/campaigns/${environment.campaignId}/owners/${cnpj}/years/${year}/months/${month}`;
          let httpOptions = {
              headers: new HttpHeaders({
                  'Authorization': environment.secToken,
              })
          }
          this.http.get(url, httpOptions).subscribe((res: any) => {
              resolve(res);
          }, err => {
              reject(err);
          });
      });
  }

  async getGoalsBySeller(cnpj, cpf, year) {
      let authToken = await this.auth.auth.currentUser.getIdToken();
      let options = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${authToken}`,
          })
      };
      return new Promise((resolve, reject) => {
          const url = `https://catalogomm12.com.br:4201/points/getGoalsByOwners/campaign/ee4d7a9e-7b89-4bb7-a792-1b97891bbd8b/owners/${cnpj}/members/${cpf}/years/${year}`;          
          this.http.get(url, options).subscribe((res: any) => {
              resolve(res);
          }, (err) => {
              console.error(err);
              reject(err);
          });
      });
  }

  async getSemesterPoints(cpf) {
      let authToken = await this.auth.auth.currentUser.getIdToken();
      let options = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${authToken}`,
          })
      };
      return new Promise((resolve, reject) => {
          const url = `https://catalogomm12.com.br:4201/points/getSemesterPoints/campaign/ee4d7a9e-7b89-4bb7-a792-1b97891bbd8b/members/${cpf}`;          
          this.http.get(url, options).subscribe((res: any) => {
              resolve(res);
          }, (err) => {
              console.error(err);
              reject(err);
          });
      });
  }

  getGoalsForMonthBySeller(month, cnpj, cpf, year) {
      return new Promise((resolve, reject) => {
          const url = `${environment.engineEndpoint}/v1/goals/totals/campaigns/${environment.campaignId}/owners/${cnpj}/members/${cpf}/years/${year}/months/${month}`;
          let httpOptions = {
              headers: new HttpHeaders({
                  'Authorization': environment.secToken,
              })
          }
          this.http.get(url, httpOptions).subscribe((res: any) => {
              resolve(res);
          }, err => {
              reject(err);
          });
      });
  }

}
