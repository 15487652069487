import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import * as $ from 'jquery'
import { UserService } from 'src/app/services/user.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  public user: any = { avatar: '' };
  balance: any = '0';
  percentGoals: any = '0';
  dropdown = false;
  expiring: any = '0';
  notifications: any[] = [];

  constructor(
    private userService: UserService,
    private router: Router,
    private auth: AngularFireAuth,
    private db: AngularFirestore) { }

  ngOnInit() {
    this.showComponents();
    this.userService.getUserData().then(async (user: any) => {
      this.user = user;
      this.user.fullName = this.user.name;
      this.user.name = this.user.name.split(' ')[0]; // Só primeiro nome
      // this.user.name = this.user.name.split(' ')[0] + ' ' + this.user.name.split(' ')[this.user.name.split(' ').length - 1]; // Primeiro e último nome
      this.userService.verifyBalanceUser().then((balance: any) => {
        this.balance = balance.balance.toFixed(2);
        this.expiring = balance.expiringPoints.toFixed(2);
        this.percentGoals = Math.floor(balance.percentGoals);
      });
      let notifications = (await this.db.collection('personalNotifications').ref.where('cpf', '==', user.cpf).orderBy('createdAt', 'desc').limit(25).get()).docs.map(d => d.data());
      notifications.push(...(await this.db.collection('generalNotifications').ref.orderBy('createdAt', 'desc').limit(25).get()).docs.map(d => d.data()));
      notifications.sort(function (a, b) {
        if (a.createdAt > b.createdAt) {
          return -1;
        }
        if (a.createdAt < b.createdAt) {
          return 1;
        }
        // a must be equal to b
        return 0;
      });
      // console.log(notifications)
      this.notifications = notifications
    });
  }

  goToLink(not) {
    if (not.type == 'training') {
      this.router.navigate(['treinamentos'])
    } else if (not.type == 'mission') {
      this.router.navigate(['missoes'])

    }
  }

  showComponents() {
    $(".desktop_logo").fadeTo(400, 1)
    $(".desktop_link1").delay(400).fadeTo(400, 1)
    $(".desktop_link2").delay(600).fadeTo(400, 1)
    $(".desktop_link3").delay(800).fadeTo(400, 1)
    $(".desktop_link4").delay(1000).fadeTo(400, 1)
    $(".desktop_img").delay(1200).fadeTo(400, 1)
    $(".desktop_texts").delay(1400).fadeTo(400, 1)
    $(".desktop_icon1").delay(1600).fadeTo(400, 1)
    $(".desktop_icon2").delay(1600).fadeTo(400, 1)
    $(".desktop_topLinks").delay(1600).fadeTo(400, 1)

    $(".mobile_logo").delay(800).fadeTo(400, 1)
    $(".mobile_icon1").delay(1200).fadeTo(400, 1)
    $(".mobile_icon2").delay(1400).fadeTo(400, 1)
    $(".mobile_icon3").delay(1600).fadeTo(400, 1)
    $(".mobile_avatarContainer").delay(1800).fadeTo(400, 1)
    $(".mobile_nameContainer").delay(2000).fadeTo(400, 1)
  }

  menuToggle(bool) {
    $('.mobile_menu').animate({ left: bool ? '0' : '-150vw' }, 458, 'swing', function () { });
  }

  goTo() {
    this.router.navigate(['resgates'])
  }

  goToPromo() {
    window.open('https://www.essilorconecta.com.br', '_blank')
  }

  logout() {
    this.auth.auth.signOut();
    this.router.navigate(['login']);
  }

}
