import { Component, OnInit, Inject } from '@angular/core';
import { AnimationEvent } from '@angular/animations';

import { ToastData, TOAST_CONFIG_TOKEN, ToastConfig } from './toast-config';
import { ToastRef } from './toast-ref';
import { toastAnimations, ToastAnimationState } from './toast-animation';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [toastAnimations.fadeToast],
})
export class ToastComponent implements OnInit {
  animationState: ToastAnimationState = 'default';
  iconType: string;

  private intervalId;

  /* TOAST */
  hasViewed: boolean = false;
  subMessage: string;
  message: string;
  toastVisible: boolean = false;
  imgName: string;
  imgLink: string = `../../../assets/images/selos/${this.imgName}.svg`;
  /* END OF TOAST */

  constructor(
    readonly data: ToastData,
    readonly ref: ToastRef,
    @Inject(TOAST_CONFIG_TOKEN) public toastConfig: ToastConfig
  ) {
    this.iconType = data.type === 'success' ? 'stamps' : data.type;
   }

  ngOnInit() {
    this.intervalId = setTimeout(() => this.animationState = 'closing', 3000);
  }

  ngOnDestrou() {
    clearTimeout(this.intervalId);
  }

  close() {
    this.ref.close();
  }

  onFadeFinished(event: AnimationEvent) {
    const { toState } = event;
    const isFadeOut = (toState as ToastAnimationState) === 'closing';
    const isFinished = this.animationState === 'closing';

    if(isFadeOut && isFinished) {
      this.close();
    }
  }

  // resolveToast = (v: boolean) => {

  //   if ( v === true) {
  //     $('#mainToast:hidden').fadeIn(1000);
  //     $('#linkToast:hidden').fadeIn(1000);
  //   } else {
  //     $('#mainToast').fadeOut(1000);
  //   }
  // }
}
