import { InjectionToken, TemplateRef } from '@angular/core';

export class ModalData {
  title?: string;
  message?: any;
  type?: string;
  tipo?: string;
}

export type ModalType = 'warning' | 'info' | 'success' | 'curtidas';

export interface ModalConfig {
    position?: {
        top: number;
        left: number;
    };
    animation?: {
        fadeOut?: number;
        fadeIn: number;
    };
}

export const defaultModalConfig: ModalConfig = {
    position: {
        top: 0,
        left: 0,
    },
    animation: {
        fadeOut: 0,
        fadeIn: 300,
    },
};

export const MODAL_CONFIG_TOKEN = new InjectionToken('modal-config');