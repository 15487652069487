import { Injectable, EventEmitter } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserModel } from '../model/user';
import * as firebase from 'firebase/app';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OpticalModel } from '../model/optical';
import { NIVEIS } from '../app.constant';
import { environment } from '../../environments/environment.prod';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { FirebaseService } from './firebase.service';

@Injectable()
export class UserService {
  CAMPAIGN_ID = environment.campaignId;
  STATEMENTS_URL = environment.engineEndpoint + '/v1/statements/campaigns/' + this.CAMPAIGN_ID + '/members';
  FACTORS_URL = environment.engineEndpoint + '/v1/factors/members';
  httpOptions = {
    headers: new HttpHeaders({
      'Authorization': environment.secToken,
    })
  }
  statements: any = {};
  increaseFactor = 0;
  spentFactor = 0;
  gatewayFactor = 0;
  user = {} as UserModel;
  stamps: any[] = [];
  balance = 0;
  expiringPoints = 0;
  cpf = '';
  addPoints: EventEmitter<Number>;
  updateBalance: EventEmitter<Number>;
  expirePoints: EventEmitter<Number>;

  constructor(
    private router: Router,
    private authFirestore: AngularFireAuth,
    private angularFirestore: AngularFirestore,
    private http: HttpClient,
    private firebaseService: FirebaseService
  ) {
    const compName: string = `user.service`;
    const funcName: string = `constructor`;
    this.resetUser();
    this.addPoints = new EventEmitter();
    this.updateBalance = new EventEmitter();
    this.expirePoints = new EventEmitter();
    this.addPoints.subscribe(
      async () => {
        let authToken = await this.authFirestore.auth.currentUser.getIdToken();
        let options = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${authToken}`
          })
        };
        const url = `https://catalogomm12.com.br:4201/points/getBalanceByUser/campaign/ee4d7a9e-7b89-4bb7-a792-1b97891bbd8b/members/${this.user.cpf}/owners/${this.user.cnpj}`;
        http.get(url, options)
          .toPromise()
          .then((result: any) => {
            this.balance = result.balance;
            this.updateBalance.emit(this.balance);
            this.expiringPoints = result.expiringPoints;
            this.expirePoints.emit(this.expiringPoints)
          });
      });
  }

  public async getToken() {
    return await this.authFirestore.auth.currentUser.getIdToken();
  }

  public async getUserData() {
    return new Promise((resolve, reject) => {
      this.authFirestore.auth.onAuthStateChanged((user) => {
        if (user) {
          if (!this.user.cpf) {
            this.angularFirestore.collection('sellers').doc(this.authFirestore.auth.currentUser.email.split('@')[0]).ref.get().then((user: any) => {
              this.user = user.data();
              resolve(user.data());
            });
          } else {
            resolve(this.user);
          }
        }
      });
    });
  }

  getUserBalance(token, userCpf) {
    let httpOptions = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${token}`
      }
    }
    let data = {
      cpf: userCpf,
    };
    let url = "https://catalogomm12.com.br:4201/firebase/balance";
    return this.http.post<any>(url, data, httpOptions).pipe(
      map((response: any) => {
        return response.data;
      }
      ),
      catchError(this.handleError())
    );
  }

  async getCategory(userCpf) {
    let category = null;
    let user = await this.angularFirestore.collection('sellers').doc(userCpf).get();
    if (user) {
      await user.forEach(u => {
        let data = u.data();
        category = data.category;
      })
    }
    return category;
  }

  async getUserAddress(userCpf) {
    let userAddress = [];
    let user = await this.angularFirestore.collection('sellers').doc(userCpf).get();
    if (user) {
      await user.forEach(u => {
        let data = u.data();
        let json = {
          street: data.address,
          cep: data.cep,
          city: data.city,
          details: data.details,
          state: data.state,
          neighbor: data.neighbor,
          number: data.number
        }
        userAddress.push(json);
      })
      return userAddress;
    }
  }

  addStampConectado() {
    let currentStamps = this.user.stamps;
    let stamps = [];
    let have = false;
    if (currentStamps) {
      for (let s of currentStamps) {
        if (s.id == '4Fu6eL5hLhGlMqQ2b7yZ') {
          have = true;
        }
      }
      if (!have) {
        let newStampConectado = {
          conquestedAt: new Date().toISOString(),
          id: '4Fu6eL5hLhGlMqQ2b7yZ',
          hasNotify: false,
        };
        currentStamps.push(newStampConectado);
        this.angularFirestore.firestore.collection('sellers').doc(this.user.cpf).update({
          stamps: currentStamps
        });
      }
    } else {
      let newStampConectado = {
        conquestedAt: new Date(),
        id: '4Fu6eL5hLhGlMqQ2b7yZ',
        hasNotify: false,
      };
      stamps.push(newStampConectado);
      this.angularFirestore.firestore.collection('sellers').doc(this.user.cpf).update({
        stamps: stamps
      });
    }
  }

  changePass(cpf, newpass) {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/json; charset=utf-8');
      let url = "https://us-central1-essilor-prd.cloudfunctions.net/api/changePassword/" + cpf + '/' + newpass
      this.http.get(url, this.httpOptions)
        .subscribe((r) => {
          resolve(r)
        })
    })
  }

  // Ver se vai precisar.
  // getIP() {
  //   setTimeout(() => {
  //     const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
  //     let url = 'https://ipapi.co/json/';
  //     this.http.get(url, this.httpOptions)
  //       .subscribe((r) => {
  //         let ip = r['ip'];
  //         this.angularFirestore.collection('downloads').doc(this.user.cpf).set({
  //           ip: ip,
  //           updateAt: new Date().toISOString(),
  //           cpf: this.user.cpf
  //         });
  //       })
  //   }, 4000)
  // }

  resetUser() {
    this.user.cpf = '';
    this.user.rg = '';
    this.user.nickname = '';
    this.user.name = '';
    this.user.gender = '';
    this.user.dateOfBirth = '';
    this.user.category = NIVEIS.PRATA;
    this.user.email = '';
    this.user.phoneNumber = '';
    this.user.fixPhoneNumber = '';
    this.user.avatar = '';
    this.user.matiralStatus = '';
    this.user.trainingsConcluded = [];
    this.user.stamps = [];
    this.user.missions = [];
    this.user.regulation = false;
    this.user.cep = '';
    this.user.address = '';
    this.user.number = '';
    this.user.details = '';
    this.user.neighbor = '';
    this.user.city = '';
    this.user.state = '';
    this.user.reference = '';
    this.user.viewTour = false;
    this.user.optical = {} as OpticalModel;
    this.user.optical.name = '';
    this.user.optical.companyName = '';
    this.user.optical.address = '';
    this.user.optical.city = '';
    this.user.optical.state = '';
    this.user.optical.details = '';
    this.user.optical.reference = '';
    this.user.optical.number = '';
    this.user.optical.cnpj = '';
    this.user.optical.neighbor = '';
    this.user.creationDate = '';
  }

  verifyToken(cpf) {
    return new Promise((resolve, reject) => {
      this.angularFirestore.collection('tokensFCM')
        .ref
        .where('sellerId', '==', cpf)
        .get()
        .then((res) => {
          let array = [];
          res.forEach((i) => {
            array.push(i.data());
          });
          resolve(array);
        })
    })
  }

  updateToken(cpf, token) {
    return new Promise((resolve, reject) => {
      this.angularFirestore.collection('tokensFCM').doc(cpf).set({
        sellerId: cpf,
        token: token
      })
        .then(() => {
          resolve();
        })
    })
  }

  async userIncomplete(email) {
    const compName: string = `user.service`;
    const funcName: string = `userIncomplete`;
    let res: any = await new Promise((resolve, reject) => {
      return this.firebaseService.getStampByName('Bem-Vindo').then((stamp) => {
        let stampsTemp = this.user.stamps ? this.user.stamps : [];
        if (!stampsTemp.find(obj => obj.id === stamp[0].id)) {
          stampsTemp.push(stamp[0]);
          if (!this.user.optical) {
            this.user.optical = {} as OpticalModel;
          }
          let opticalTemp: OpticalModel = {
            name: this.user.optical.name ? this.user.optical.name : '',
            companyName: this.user.optical.companyName ? this.user.optical.companyName : '',
            address: this.user.optical.address ? this.user.optical.address : '',
            city: this.user.optical.city ? this.user.optical.city : '',
            state: this.user.optical.state ? this.user.optical.state : '',
            details: this.user.optical.details ? this.user.optical.details : '',
            reference: this.user.optical.reference ? this.user.optical.reference : '',
            number: this.user.optical.number ? this.user.optical.number : '',
            cnpj: this.user.optical.cnpj ? this.user.optical.cnpj : '',
            neighbor: this.user.optical.neighbor ? this.user.optical.neighbor : '',
          }
        } else {
          resolve();
        }
      })
    });
    return res;
  }

  async login(email, password) {
    return new Promise((resolve, reject) => {
      email += '@cv.com';
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
        this.authFirestore.auth.signInWithEmailAndPassword(email, password).then((result) => {
          // console.log(result, 'resolvendo');
          resolve(result);
        }).catch((err) => {
          if (err.code === 'auth/wrong-password') {
            alert('Senha Incorreta.')
          } else if (err.code === 'auth/user-not-found') {
            alert('Email não cadastrado.')
          }
          reject(err);
        });
      })
    })
  }

  async login2(email, password) {
    return new Promise((resolve, reject) => {
      email += '@cv.com.br';
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
        this.authFirestore.auth.signInWithEmailAndPassword(email, password).then((result) => {
          // console.log(result, 'resolvendo');
          resolve(result);
        }).catch((err) => {
          if (err.code === 'auth/wrong-password') {
            alert('Senha Incorreta.')
          } else if (err.code === 'auth/user-not-found') {
            alert('Email não cadastrado.')
          }
          reject(err);
        });
      })
    })
  }

  async getUser(cpf) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.firestore.collection('sellers').doc(cpf).get().then((result) => {
        resolve(result.data());
      }).catch((err) => {
        reject(err);
      });
    });
  }

  async getUserByHash(hash) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.firestore.collection('recover-pass')
        .where('hash', '==', hash)
        .get().then((result) => {
          let items = [];
          result.forEach((r) => {
            items.push(r.data());
          });
          if (items.length > 0) {
            this.angularFirestore.firestore.collection('sellers').doc(items[0].cpf).get().then((result) => {
              resolve(result.data());
            }).catch(() => {
              reject()
            })
          }
          else {
            reject()
          }
        }).catch((err) => {
          reject(err);
        });
    });
  }

  async getUserByNickname(nickname: string): Promise<any> {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection('sellers').ref.where('nickname', '==', nickname).get().then((usersResult: any) => {
        if (usersResult.empty) {
          resolve(null);
        } else {
          resolve({
            id: usersResult.docs[0].id,
            data: usersResult.docs[0].data()
          });
        }
      }).catch(error => {
        console.log(error);
        reject(error);
      });
    });
  }

  async saveUser(user, password) {
    user.avatar = '';
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection('sellers').doc(user.cpf).set(user).then((newManager: any) => {
        this.authFirestore.auth.createUserWithEmailAndPassword(user.cpf + '@cv.com', password).then((res) => {
          resolve(newManager);
        }).catch((err) => {
          reject(err);
        })
      }).catch((error) => {
        console.log(error);
        reject(error);
      });
    });
  }

  async newUser(user, password) {
    user.avatar = '';
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection('sellers').doc(user.cpf).update({ password: password }).then((newManager: any) => {
        this.authFirestore.auth.createUserWithEmailAndPassword(user.cpf + '@cv.com', password).then((res) => {
          resolve(newManager);
        }).catch((err) => {
          reject(err);
        })
      }).catch((error) => {
        console.log(error);
        reject(error);
      });
    });
  }

  changeUserEmail(id, email) {
    return new Promise((resolve, reject) => {
      this.angularFirestore.collection('sellers').doc(id).update({ email: email })
        .then(() => {
          resolve();
        })
    })
  }

  verifyUserEmail(cpf) {
    return new Promise((resolve, reject) => {
      this.angularFirestore.collection('sellers').ref.where('cpf', '==', cpf).get().then((result) => {
        let array = [];
        result.forEach((r) => {
          array.push(r.data());
        });
        resolve(array);
      }).catch((err) => {
        reject(err);
      });
    })
  }

  async updateUser(user, cpf) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection('sellers').doc(cpf).update(user).then((result) => {
        resolve();
      }).catch((err) => {
        reject(err);
      });
    });
  }

  changeUserSegmentId(body, cpf) {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/json; charset=utf-8');
      let url = `https://us-central1-essilor-prd.cloudfunctions.net/userService/api/changeMember/${cpf}`
      this.http.post(url, body, this.httpOptions)
        .subscribe((r) => {
          resolve(r)
        })
    })
  }

  getGoals(body) {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/json; charset=utf-8');
      let url = `https://us-central1-iloyalty-prd.cloudfunctions.net/userService/api/userGoals`
      this.http.post(url, body, this.httpOptions)
        .subscribe((r) => {
          resolve(r)
        })
    })
  }

  async changePassword(email, sendEmail, newPassword) {
    email += '@cv.com';
    return await new Promise((resolve, reject) => {
      if (sendEmail) {
        this.authFirestore.auth.sendPasswordResetEmail(email).then((res: any) => {
          resolve(res);
        }).catch((err) => {
          reject(err)
        });
      } else {
        this.authFirestore.auth.currentUser.updatePassword(newPassword).then((res: any) => {
          resolve(res);
        }).catch((err) => {
          reject(err);
        })
      }
    });
  }


  async verifyUserLogged() {
    return await new Promise((resolve, reject) => {
      let url = window.location.pathname;
      if (url != 'inserir-pontos') {
        firebase.auth().onAuthStateChanged((user: any) => {
          if (user) {
            this.getUser(user.providerData[0].email.split('@')[0]).then((userResult: any) => {
              this.user = userResult;
              resolve(this.user);
            }).catch((err) => {
              reject(err);
            });
          } else {
            // this.router.navigate(['/login']);
          }
        });
      }
    });
  }

  async verifyBalanceUser() {
    return await new Promise((resolve, reject) => {
      let user: any = this.verifyUserLogged().then(async (userResult: any) => {
        let authToken = await this.authFirestore.auth.currentUser.getIdToken();
        let options = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${authToken}`
          })
        };
        const url = `https://catalogomm12.com.br:4201/points/getBalanceByUser/campaign/ee4d7a9e-7b89-4bb7-a792-1b97891bbd8b/members/${userResult.cpf}/owners/${userResult.cnpj}`;
        this.http.get(url, options).toPromise().then(
          (result: any) => {
            this.balance = result.balance;
            this.updateBalance.emit(this.balance);
            this.expiringPoints = result.expiringPoints
            this.expirePoints.emit(this.expiringPoints)
            resolve(result)
          }).catch(err => {
            console.log('error verifyBalanceUser: ', err)
            reject(err)
          });
      })
    })
  }

  addPapaPontosStamp = async () => {
    let cpf = await this.verifyUserLogged().then((userResult: any) => {
      return userResult.cpf;
    });
    let url: string = environment.engineEndpoint + '/v1/balances/campaigns/' + environment.campaignId + '/members/' + cpf;
    let balance = await this.http.get(url, this.httpOptions).toPromise().then((result: any) => {
      return result.balance;
    });

    let stampArray = [];
    let thisDay = new Date().getDate();
    let havePapa = false;
    let month = new Date().getMonth() + 1;
    let quantity;

    if (thisDay == 28) {
      if (cpf) {
        this.angularFirestore.firestore.collection('sellers').doc(cpf).get().then(async (res) => {
          let data = res.data();
          let stamps = data.stamps;
          if (balance > 50000) {
            if (!data.monthPapa) {
              for (let s of stamps) {
                if (s.id == '7jls9XzSRtDIKX6Rb55v') {
                  havePapa = true;
                  quantity = s.quantity + 1;
                } else {
                  stampArray.push(s);
                }
              }
              if (!havePapa) {
                let newStampPapa = {
                  conquestedAt: new Date(),
                  id: '7jls9XzSRtDIKX6Rb55v',
                  hasNotify: false,
                  quantity: 1
                }
                stampArray.push(newStampPapa);
                this.angularFirestore.firestore.collection('sellers').doc(cpf).update({
                  monthPapa: month,
                  stamps: stampArray
                });
              } else {
                let newStampPapaHave = {
                  conquestedAt: new Date(),
                  id: '7jls9XzSRtDIKX6Rb55v',
                  hasNotify: false,
                  quantity: quantity,
                }
                stampArray.push(newStampPapaHave);
                this.angularFirestore.firestore.collection('sellers').doc(cpf).update({
                  stamps: stampArray,
                  monthPapa: month
                });
              }
            } else {
              if (data.monthPapa == month) {
                return;
              } else {
                for (let s of stamps) {
                  if (s.id == '7jls9XzSRtDIKX6Rb55v') {
                    havePapa = true;
                    quantity = s.quantity + 1;
                  } else {
                    stampArray.push(s);
                  }
                }
                if (!havePapa) {
                  let newStampPapa = {
                    conquestedAt: new Date(),
                    id: '7jls9XzSRtDIKX6Rb55v',
                    hasNotify: false,
                    quantity: 1
                  }
                  stampArray.push(newStampPapa);
                  this.angularFirestore.firestore.collection('sellers').doc(cpf).update({
                    stamps: stampArray,
                    monthPapa: month
                  });
                } else {
                  let newStampPapaHave = {
                    conquestedAt: new Date(),
                    id: '7jls9XzSRtDIKX6Rb55v',
                    hasNotify: false,
                    quantity: quantity,
                  }
                  stampArray.push(newStampPapaHave);
                  this.angularFirestore.firestore.collection('sellers').doc(cpf).update({
                    stamps: stampArray,
                    monthPapa: month
                  });
                }
              }
            }
          }
        });
      }
    }
  }

  getUserUnlogged(cpf) {
    this.getUser(cpf).then((userResult: any) => {
      this.user = userResult;
      let url: string = environment.engineEndpoint + '/v1/balances/campaigns/' + environment.campaignId + '/members/' + this.user.cpf;
      this.http.get(url, this.httpOptions).toPromise().then((result: any) => {
        this.balance = result.balance;
        this.updateBalance.emit(this.balance);
        this.expiringPoints = result.expiringPoints
        this.expirePoints.emit(this.expiringPoints)
      });
    })
  }

  async logout() {
    return await firebase.auth().signOut().finally(() => {
      this.router.navigate(['/login']);
    });
  }

  async resetPassword(cpf) {
    return await new Promise((resolve, reject) => {
      this.http.get('https://us-central1-essilor-prd.cloudfunctions.net/api/seller/${cpf}/password/reset', this.httpOptions).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
        console.log('erro ao resetar a senha ', err)
      })
    })
  }

  async isStatementExist(body, cpf, campaignId) {
    return await new Promise((resolve, reject) => {
      let url = `https://us-central1-iloyalty-prd.cloudfunctions.net/userService/api/isMissionExist/?cpf=${cpf}&campaignId=${campaignId}`
      this.http.post<any>(url, body, this.httpOptions).toPromise().then((response) => {
        resolve(response);
      }).catch((err) => {
        reject(err);
      });
    })
  }

  async getOpticalByCNPJ(cnpj) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection('owners').doc(cnpj).ref.get().then((company) => {
        resolve(company.data());
      }).catch((err) => {
        reject(err);
      })
    })
  }

  async getMemberCNPJ(cnpj) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection('member').doc(cnpj.toString()).ref.get().then((company) => {
        resolve(company.data());
      }).catch((err) => {
        reject(err);
      })
    })
  }

  updateFactors() {
    if (this.gatewayFactor === 0 || this.spentFactor === 0 || this.increaseFactor === 0) {
      if (sessionStorage.getItem('f')) {
        const a = JSON.parse(sessionStorage.getItem('f'));
        if (!a || !a.g) {
          return;
        }
        this.gatewayFactor = a.g ? a.g : '';
        this.spentFactor = a.s ? a.s : '';
        this.increaseFactor = a.i ? a.i : '';
      }
    }
  }

  updateStatements() {
    this.getUserStatements(this.user.cpf).toPromise().then((resp: any) => {
      if (!resp) {
        alert('Não foi possível obter o extrato.');
        return;
      }
      this.statements = resp;
      this.balance = resp.balance;
      this.expiringPoints = resp.balance;
    });
  }

  getUserStatements(id: string): Observable<any> {
    const url = `${this.STATEMENTS_URL}/${id}/expiring/1`;
    return this.http.get<any>(url, this.httpOptions).pipe(
      map((resp) => {
        return resp;
      }),
      catchError(this.handleError('get user statements'))
    );
  }

  getFactors(id: string): Observable<any> {
    const url = `${this.FACTORS_URL}/${id}/campaigns/${this.CAMPAIGN_ID}`;
    return this.http.get<any>(url, this.httpOptions).pipe(
      map((resp) => resp),
      catchError(this.handleError('get user factors'))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }


  async postMember(body: any): Promise<any> {
    const url = `https://us-central1-iloyalty-prd.cloudfunctions.net/engine/v1/statements`;
    let fetchOptions: RequestInit = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': environment.secToken,
      },
      body: JSON.stringify(body),
    }
    let result: any =
      await fetch(url, fetchOptions).then(
        async (response) => {
          let json: any = await response.json();
          return json;
        }).catch(
          (reason) => {
            return { result: false, data: { reason: { serverMessage: `Erro durante a requisição ao servidor.`, reason: reason } } };
          });
    return result;
  }

}
