import { Injectable, Injector, Inject } from '@angular/core';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';

import { ModalComponent } from './modal.component';
import { ModalData, ModalConfig, MODAL_CONFIG_TOKEN } from './modal-config';
import { ModalRef } from './modal-ref';

@Injectable({
  providedIn: 'root'
})

export class ModalService {
  private lastToast: ModalRef;

  constructor(
    private overlay: Overlay,
    private parentInjector: Injector,
    @Inject(MODAL_CONFIG_TOKEN) private toastConfig: ModalConfig
  ) { }

  show(data: ModalData) {
    const positionStrategy = this.getPositionStrategy();
    const overlayRef = this.overlay.create({positionStrategy});

    const modalRef = new ModalRef(overlayRef);
    this.lastToast = modalRef;

    const injector = this.getInjector(data, modalRef, this.parentInjector);
    const modalPortal = new ComponentPortal(ModalComponent, null, injector);

    overlayRef.attach(modalPortal);

    return modalRef;
  }

  getPositionStrategy() {
    return this.overlay.position()
      .global()
      .top(this.getPosition())
      .left(this.toastConfig.position.left + 'px');
  }

  getPosition = () => {
    const lastToastIsVisible = this.lastToast && this.lastToast.isVisible();
    const position = lastToastIsVisible
      ? this.lastToast.getPosition().bottom
      : this.toastConfig.position.top;

    return position + 'px';
  }

  getInjector(data: ModalData, modalRef: ModalRef, parentInjector: Injector) {
    const tokens = new WeakMap();

    tokens.set(ModalData, data);
    tokens.set(ModalRef, modalRef);

    return new PortalInjector(parentInjector, tokens);
  }
}