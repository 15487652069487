import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { environment } from '../environments/environment';

import { AngularFirestoreModule, AngularFirestore, FirestoreSettingsToken } from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { NgxSmartModalModule } from 'ngx-smart-modal';

import { UserService } from './services/user.service';
import { FirebaseService } from './services/firebase.service';
import { DashboardService } from './services/dashboard.service';
import { HttpClientModule } from '@angular/common/http';

import { ToastModule } from './utils/toast';
import { ModalModule } from './utils/modal';
import { OverlayModule } from '@angular/cdk/overlay';
import { HeaderModule } from './components/header/header.module';
import { FooterModule } from './components/footer/footer.module';

import { Ng2ImgMaxModule } from 'ng2-img-max';
import {NgxMaskIonicModule} from 'ngx-mask-ionic'
import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt'; 
import { Camera } from '@ionic-native/camera/ngx';
registerLocaleData(ptBr)

@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence(),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireMessagingModule,
    HttpClientModule,
    NgxSmartModalModule.forRoot(),
    OverlayModule,
    ToastModule.forRoot(),
    ModalModule.forRoot(),
    NgxMaskIonicModule.forRoot(),
    HeaderModule,
    FooterModule,
    Ng2ImgMaxModule,
    HammerModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'pt' },
    UserService,
    FirebaseService,
    DashboardService,
    Camera
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
