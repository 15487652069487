import { NgModule, ModuleWithProviders } from '@angular/core'
// import { OverlayModule } from '@angular/cdk/overlay';
// import { MatIconModule } from '@angular/material/icon';

import { BrowserModule } from '@angular/platform-browser'

import { ModalComponent } from './modal.component';
import { defaultModalConfig, MODAL_CONFIG_TOKEN } from './modal-config';

@NgModule({
//   imports: [OverlayModule, MatIconModule, BrowserModule],
  imports: [BrowserModule],
  declarations: [ModalComponent],
  entryComponents: [ModalComponent]
})
export class ModalModule {
  public static forRoot(config = defaultModalConfig): ModuleWithProviders {
        return {
            ngModule: ModalModule,
            providers: [
                {
                    provide: MODAL_CONFIG_TOKEN,
                    useValue: { ...defaultModalConfig, ...config },
                },
            ],
        };
    }
 }