import { Component, OnInit, Input } from '@angular/core';
import * as $ from 'jquery'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  @Input() public logged: boolean = false;

  constructor() {
  }

  ngOnInit() {
    this.showComponents()
  }

  showComponents() {
    if (this.logged) {
      $("#notLogged").remove();
      $(".desktop_whiteline").fadeTo(400, 1);
      $(".desktop_whiteline_content_img1").delay(400).fadeTo(400, 1);
      $(".desktop_whiteline_content_img2").delay(600).fadeTo(400, 1);
      $(".desktop_blueline").delay(800).fadeTo(400, 1);
      $(".desktop_blueline_col1").delay(1000).fadeTo(400, 1);
      $(".desktop_blueline_subcol1").delay(1200).fadeTo(400, 1);
      $(".desktop_blueline_subcol2").delay(1300).fadeTo(400, 1);
      $(".desktop_blueline_subcol3").delay(1400).fadeTo(400, 1);
      $(".desktop_blueline_subcol4").delay(1500).fadeTo(400, 1);
      $(".desktop_blueline_subcol5").delay(1600).fadeTo(400, 1);
      $(".desktop_blueline_col3").delay(1800).fadeTo(400, 1);
      $(".desktop_copyright").delay(1800).fadeTo(400, 1);
    } else {
      $("#logged").remove();
      $(".mobile_footer").remove();
      $(".desktop_whiteline").fadeTo(400, 1);
      $(".desktop_whiteline_content2_img1").delay(400).fadeTo(400, 1);
      $(".desktop_whiteline_content2_img2").delay(600).fadeTo(400, 1);
      $(".desktop_copyright2").delay(1200).fadeTo(400, 1);
    }

  }

  scrollTop() {
    window.scrollTo(0, 0)
    $(window).scrollTop(0);
  }
}
