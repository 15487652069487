export enum NIVEIS {
    PRATA = "PRATA",
    OURO = "OURO",
    DIAMANTE = "DIAMANTE"
};

export enum NIVEIS_VALOR {
    PRATA = 1,
    OURO = 2,
    DIAMANTE = 4
}

export enum FACTORS {
    INCREASE = 1.06,
    SPENT = 0.011,
    GATEWAY = 1
}
