import { InjectionToken, TemplateRef } from '@angular/core';

export class ToastData {
  type?: ToastType;
  message?: string;
  subMessage?: string;
  imgLink?: string;
  imgName?: string;
}

export type ToastType = 'stamp' | 'info' | 'success' | 'error';

export interface ToastConfig {
  position?: {
      top: number;
      right: number;
  };
  animation?: {
      fadeOut: number;
      fadeIn: number;
  };
}


export const defaultToastConfig: ToastConfig = {
  position: {
      top: 20,
      right: 20,
  },
  animation: {
      fadeOut: 700,
      fadeIn: 300,
  },
};

export const TOAST_CONFIG_TOKEN = new InjectionToken('toast-config');