import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HeaderComponent } from './header.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import * as moment from 'moment'
import { MomentModule } from 'ngx-moment';
moment.locale('pt-br');


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MomentModule
    ],
    exports: [
        HeaderComponent,
    ],
    declarations: [
        HeaderComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class HeaderModule { }
