import { Component, OnInit, Inject } from '@angular/core';
import { AnimationEvent } from '@angular/animations';

import { ModalData, MODAL_CONFIG_TOKEN, ModalConfig } from './modal-config';
import { ModalRef } from './modal-ref';
import { modalAnimations, ModalAnimationState } from './modal-animation';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [modalAnimations.fadeModal],
})
export class ModalComponent implements OnInit {
  animationState: ModalAnimationState = 'default';
  iconType: string;

  private intervalId;

  /* MODAL */
  hasViewed: boolean = false;
  subMessage: string;
  message: string;
  toastVisible: boolean = false;
  imgName: string;
  /* END OF TOAST */

  constructor(
    readonly data: ModalData,
    readonly ref: ModalRef,
    @Inject(MODAL_CONFIG_TOKEN) public modalConfig: ModalConfig
  ) {
    
    this.iconType = data.type === 'success' ? 'stamps' : data.type;
   }

  ngOnInit() {
    this.intervalId = setTimeout(() => this.animationState = 'closing', 3000);
  }

  ngOnDestrou() {
    clearTimeout(this.intervalId);
  }

  close() {
    this.ref.close();
  }

  onFadeFinished(event: AnimationEvent) {
    const { toState } = event;
    const isFadeOut = (toState as ModalAnimationState) === 'closing';
    const isFinished = this.animationState === 'closing';

    if(isFadeOut && isFinished) {
      this.close();
    }
  }

  // resolveToast = (v: boolean) => {

  //   if ( v === true) {
  //     $('#mainToast:hidden').fadeIn(1000);
  //     $('#linkToast:hidden').fadeIn(1000);
  //   } else {
  //     $('#mainToast').fadeOut(1000);
  //   }
  // }
}
