import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { UserService } from './services/user.service';
import { FirebaseService } from './services/firebase.service';
import { Compiler } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AngularFireAuth } from '@angular/fire/auth';
import { take } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs'
import { filter } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import * as $ from 'jquery';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  exibeHeader = true;
  exibeFooter = true;
  currentMessage = new BehaviorSubject(null);
  showVideo: boolean = false;
  version = environment.version;
  title = 'conectavantagens';
  splash: boolean = false;
  alerts: any[] = [];
  promptEvent: any;
  public openMenu: boolean = false;
  public openNotification: boolean = false;
  promoBanner1: string = '';
  promoBanner2: string = '';
  promoBanner3: string = '';
  promoBannerLink1: string = '';
  promoBannerLink2: string = '';
  promoBannerLink3: string = '';
  isLabUser = false;
  chatbotEnabled = true;
  showChatbot = false;
  showChatbotFirstClick = false;
  loadingChatbot = false;
  chatbotQuestion = { "id": "", "text": "", "next": [] };
  chatbotAnswers = []
  chatbotDocs = []
  chatbotHistory = ["Q1"];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private _compiler: Compiler,
    private router: Router,
    public userService: UserService,
    private firebaseService: FirebaseService,
    private angularFireMessaging: AngularFireMessaging,
    private angularFireAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private http: HttpClient,
    private rt: ActivatedRoute
  ) {
    this.initializeApp();
    const navEndEvent$ = router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    );

    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    );

    this.initCart();

    this._compiler.clearCache();
    this.firebaseService.verifyVersion()
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  async ngOnInit() {
    firebase.auth().onAuthStateChanged(async (user: any) => {
      if (user) {
        let device = "Desktop";
        if ($(window).width() < 1025) { device = "Mobile" }
        let accessDate = new Date();
        let id = accessDate.getTime();
        let cpfCnpj = user.email.slice(0, -7);
        let userType = "Vendedor"
        let campaign = "CV";
        this.firestore.collection('accessLogs').doc(id.toString()).set({
          "user": cpfCnpj,
          "userType": userType,
          "campaign": campaign,
          "device": device,
          "date": accessDate,
          "id": id
        })
        await this.firebaseService.getConfigs();
        await this.firestore.collection('sellers').doc(cpfCnpj.toString()).ref.get().then(seller => {
          if (seller.data().status == "M") {
            alert("Esta conta foi migrada para uma conta Gerente Varilux Especialista")
            this.userService.logout()
          }
          if (seller.data().status == "I") {
            alert("Esta conta foi inativada, por favor entre em contato com o SAC")
            this.userService.logout()
          }
        });
      } else {
        if (window.location.href.split('/')[window.location.href.split('/').length - 1] != 'cadastro') {
          this.router.navigate(['login']);
        }
      }
    });
    setTimeout(() => {
      let env = this;
      let jabaixou = localStorage.getItem('jabaixou');
      let mobile = this.isMobileDevice();
      //Verify user have FCM token
      this.userService.verifyToken(this.userService.user.cpf)
        .then((r) => {
          let res = JSON.parse(JSON.stringify(r));
          if (res.length == 0) {
            this.requestPermission();
          }
          else {
            this.receiveMessage();
          }
        })
      if (mobile && !env.isLabUser) { // If pra previnir de consultar mais de 1 vez
        env.firestore.collection('labUsers').doc(this.userService.user.cpf.toString()).ref.get().then(labUser => {
          if (labUser.exists) {
            env.isLabUser = true;
          }
        })
      }

    }, 5000)

    this.getSystemAlerts();
    this.firebaseService.getPromoBanners().then((promoBanners: any) => {
      this.promoBanner1 = promoBanners.data().banner1;
      this.promoBanner2 = promoBanners.data().banner2;
      this.promoBanner3 = promoBanners.data().banner3;
      this.promoBannerLink1 = promoBanners.data().bannerLink1;
      this.promoBannerLink2 = promoBanners.data().bannerLink2;
      this.promoBannerLink3 = promoBanners.data().bannerLink3;
    })
  }

  toggleChatbot(bool) {
    if(window.location.toString().indexOf('conectados') != -1) return;
    if (bool && !this.showChatbotFirstClick) {
      this.showChatbotFirstClick = true;
      this.loadChatbot()
    }
    this.showChatbot = bool
  }
  loadChatbot() {
    this.loadingChatbot = true;
    this.chatbotDocs = [];
    this.chatbotAnswers = [];
    this.chatbotHistory = ['Q1'];
    this.firestore.collection("chatbot").ref.get().then(docs => {
      docs.docs.forEach(doc => {
        this.chatbotDocs.push(doc.data())
      })
      this.chatbotQuestion = this.chatbotDocs.filter(doc => { return doc.id == "Q1" })[0]
      this.chatbotQuestion.next.forEach(ans => {
        this.chatbotAnswers.push(this.chatbotDocs.filter(doc => { return doc.id == ans })[0])
      })
      this.loadingChatbot = false;
    }).catch(err => {
      this.loadingChatbot = false;
      console.log(err)
      alert("Erro ao carregar perguntas, verifique sua conexão e tente novamente.")
    })
  }
  changeQuestion(id) {
    this.chatbotQuestion = this.chatbotDocs.filter(doc => { return doc.id == id })[0];
    this.chatbotHistory.push(id)
    // console.log(this.chatbotHistory)
    this.chatbotAnswers = [];
    this.chatbotQuestion.next.forEach(ans => {
      this.chatbotAnswers.push(this.chatbotDocs.filter(doc => { return doc.id == ans })[0])
    })
  }
  endChatbot() {
    this.chatbotEnabled = false
  }
  chatbotBack() {
    this.changeQuestion(this.chatbotHistory[this.chatbotHistory.length - 2]);
    this.chatbotHistory.pop(); // Remove o último item
    this.chatbotHistory.pop(); // Remove o penúltimo item
  }

  requestPermission() {
    if (this.userService.user.cpf) {
      this.angularFireMessaging.requestToken.subscribe(
        (token) => {
          this.userService.updateToken(this.userService.user.cpf, token).then(() => {
            this.receiveMessage();
          })
        },
        (err) => {
          console.error('Unable to get permission to notify.', err);
        }
      );
    }
  }
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        this.currentMessage.next(payload);
      })
  }

  isMobileDevice(): boolean {
    return ($(window).width() < 1025)
  }
  goTo(router) {
    this.router.navigate([router]);
    this.openMenu = false;
  }
  getSystemAlerts() {
    this.firebaseService.getSystemAlerts().then((alerts: any) => {
      this.alerts = alerts;
    })
  }
  installPwa(): void {
    this.promptEvent.prompt();
  }
  goToTour() {
    this.userService.user.viewTour = false;
    this.userService.user.viewAcademy = false;
    this.userService.user.viewStamps = false;
    this.userService.user.viewMissions = false;
    this.userService.updateUser({
      viewTour: false,
      viewAcademy: false,
      viewStamps: false,
      viewMissions: false,
    }, this.userService.cpf);
    this.goTo('/home');
  }
  initCart(): void {
    if (localStorage.getItem('cart')) {
      return;
    } else {
      const newCart = [];
      localStorage.setItem('cart', JSON.stringify(newCart));
    }
  }

}
