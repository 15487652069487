import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'programa',
    loadChildren: () => import('./programa/programa.module').then(m => m.ProgramaPageModule)
  },
  {
    path: 'inserir-pontos',
    loadChildren: () => import('./inserir-pontos/inserir-pontos.module').then(m => m.InserirPontosPageModule)
  },
  {
    path: 'regulamento',
    loadChildren: () => import('./regulamento/regulamento.module').then(m => m.RegulamentoPageModule)
  },
  {
    path: 'extrato',
    loadChildren: () => import('./extrato/extrato.module').then(m => m.ExtratoPageModule)
  },
  {
    path: 'fale-conosco',
    loadChildren: () => import('./fale-conosco/fale-conosco.module').then(m => m.FaleConoscoPageModule)
  },
  {
    path: 'tabela',
    loadChildren: () => import('./tabela/tabela.module').then(m => m.TabelaPageModule)
  },
  {
    path: 'minha-conta',
    loadChildren: () => import('./minha-conta/minha-conta.module').then(m => m.MinhaContaPageModule)
  },
  {
    path: 'resgates',
    loadChildren: () => import('./resgates/resgates.module').then(m => m.ResgatesPageModule)
  },
  {
    path: 'conectados-home',
    loadChildren: () => import('./conectados/conectados-home/conectados-home.module').then( m => m.ConectadosHomePageModule)
  },
  {
    path: 'conectados-rankings',
    loadChildren: () => import('./conectados/conectados-rankings/conectados-rankings.module').then( m => m.ConectadosRankingsPageModule)
  },
  {
    path: 'conectados-loja',
    loadChildren: () => import('./conectados/conectados-loja/conectados-loja.module').then( m => m.ConectadosLojaPageModule)
  },
  {
    path: 'conectados-tutorial',
    loadChildren: () => import('./conectados/conectados-tutorial/conectados-tutorial.module').then( m => m.ConectadosTutorialPageModule)
  },
  {
    path: 'conectados-matchmaking',
    loadChildren: () => import('./conectados/conectados-matchmaking/conectados-matchmaking.module').then( m => m.ConectadosMatchmakingPageModule)
  },
  {
    path: 'conectados-battle',
    loadChildren: () => import('./conectados/conectados-battle/conectados-battle.module').then( m => m.ConectadosBattlePageModule)
  },
  {
    path: 'tabela',
    loadChildren: () => import('./tabela/tabela.module').then( m => m.TabelaPageModule)
  },
  {
    path: 'missoes',
    loadChildren: () => import('./missoes/missoes.module').then( m => m.MissoesPageModule)
  },
  {
    path: 'minha-conta',
    loadChildren: () => import('./minha-conta/minha-conta.module').then(m => m.MinhaContaPageModule)
  },
  {
    path: 'resgates',
    loadChildren: () => import('./resgates/resgates.module').then(m => m.ResgatesPageModule)
  },
  {
    path: 'cadastro',
    loadChildren: () => import('./cadastro/cadastro.module').then( m => m.CadastroPageModule)
  },
  {
    path: 'treinamentos',
    loadChildren: () => import('./treinamentos/treinamentos.module').then( m => m.TreinamentosPageModule)
  },
  {
    path: 'treinamento/:id',
    loadChildren: () => import('./treinamento/treinamento.module').then( m => m.TreinamentoPageModule)
  },
  {
    path: 'sso',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'notificacoes',
    loadChildren: () => import('./notificacoes/notificacoes.module').then( m => m.NotificacoesPageModule)
  },
  {
    path: 'conectados-training',
    loadChildren: () => import('./conectados/conectados-training/conectados-training.module').then( m => m.ConectadosTrainingPageModule)
  },
  {
    path: 'gerador',
    loadChildren: () => import('./gerador/gerador.module').then( m => m.GeradorPageModule)
  },
  {
    path: 'selos',
    loadChildren: () => import('./selos/selos.module').then( m => m.SelosPageModule)
  }


];

// const routes: Routes = [
//   {
//     path: "home",
//     loadChildren: "./home/home.module#HomeModule"
//   },
//   {
//     path: "login",
//     loadChildren: "./login/login.module#LoginModule"
//   },
//   {
//     path: "login/recuperar-senha",
//     loadChildren:
//       "./login/password-recovery/password-recovery.module#PasswordRecoveryModule"
//   },
//   {
//     path: "cadastro",
//     loadChildren: "./cadastro/cadastro.module#CadastroModule"
//   },
//   {
//     path: "post/:date",
//     loadChildren: "./post/post.module#PostModule"
//   },
//   {
//     path: "recover/:token",
//     loadChildren: "./recover/recover.module#RecoverModule"
//   },
//   {
//     path: "promocoes",
//     loadChildren: "./promocoes/promocoes.module#PromocoesModule"
//   },
//   {
//     path: "minha-conta",
//     loadChildren: "./minha-conta/minha-conta.module#MinhaContaModule"
//   },
//   {
//     path: "selos",
//     loadChildren: "./selos/selos.module#SelosModule"
//   },
//   {
//     path: "academy",
//     loadChildren: "./academy/academy.module#AcademyModule"
//   },
//   {
//     path: "academy/questions/:id",
//     loadChildren:
//       "./academy/academy-questions/academy-questions.module#AcademyQuestionsModule"
//   },
//   {
//     path: "fale-conosco",
//     loadChildren: "./fale-conosco/fale-conosco.module#FaleConoscoModule"
//   },
//   {
//     path: "faq",
//     loadChildren: "./faq/faq.module#FaqModule"
//   },
//   {
//     path: "inserir-pontos",
//     loadChildren: "./inserir-pontos/inserir-pontos.module#InserirPontosModule"
//   },
//   {
//     path: "inserir-pontos/:cpf/:type",
//     loadChildren: "./inserir-pontos/inserir-pontos.module#InserirPontosModule"
//   },
//   {
//     path: "missoes",
//     loadChildren: "./missoes/missoes.module#MissoesModule"
//   },
//   {
//     path: "programa",
//     loadChildren: "./programa/programa.module#ProgramaModule"
//   },
//   {
//     path: "programa/tabela",
//     loadChildren:
//       "./programa/programa-tabela/programa-tabela.module#ProgramaTabelaModule"
//   },
//   {
//     path: "programa/regulamento",
//     loadChildren:
//       "./programa/programa-regulamento/programa-regulamento.module#ProgramaRegulamentoModule"
//   },
//   {
//     path: "resgate",
//     loadChildren: "./resgate/resgate.module#ResgateModule"
//   },
//   {
//     path: "gerador",
//     loadChildren: "./gerador/gerador.module#GeradorModule"
//   },
//   {
//     path: "**",
//     loadChildren: "./login/login.module#LoginModule",
//     pathMatch: "full"
//   }
// ];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
