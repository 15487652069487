import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';

export enum TypeCertficatesModel {
  CERTIFICATE_CARD = 'Certificados de Cartão',
  CERTIFICATE_PAPER = 'Certificados de Papel',
  CERTIFICATE_PAPER_UNIQUE_CODE = 'Certificados de Papel de Código Único'
}

@Injectable()
export class FirebaseService {
  cv_pointsConversion = 0;
  mm12Tax = 0;
  httpOptions = {
    headers: new HttpHeaders({
      'Authorization': environment.secToken,
    })
  }
  lastDatePost: any;

  constructor(public angularFirestore: AngularFirestore, private http: HttpClient) { }

  async getConfigs() {
    await this.angularFirestore.firestore.collection("config").get().then((result) => {
      if (!result.empty) {
        result.forEach(conf => {
          const data = conf.data();
          this.cv_pointsConversion = data.cv_pointsConversion;
          this.mm12Tax = data.mm12Tax;
        });
      }
    })
  }

  async getPromotions() {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.firestore.collection('promotions').get().then((results) => {
        let promotions = [];
        results.forEach((promotion: any) => {
          promotions.push(promotion.data());
        });
        resolve(promotions);
      }).catch((err) => {
        reject(err);
      })
    })
  }

  getBills(cpf, date) {
    return new Promise((resolve, reject) => {
      const url = `${environment.catalogEndpoint}/bills/verifyBills/${cpf}/`;
      let httpOptions = {
        headers: new HttpHeaders({
          'Authorization': environment.secToken,
        })
      }
      let body = {
        date: date
      };
      this.http.put(url, body, httpOptions).subscribe((res: any) => {
        resolve(res);
      }, (err) => {
        console.error('error', err);
        reject(err);
      });
    })
  }

  async getBillFromCode(cpf, billCode) {
    let response = null;
    const url = `http://localhost:5000/iloyalty-prd/us-central1/api/bills/verifyBills/${cpf}/${billCode}/`;
    return this.http.get<any>(url, this.httpOptions).pipe(
      map((response) => {
        return response;
      }), catchError(error => {
        return throwError('Error at getBillFromCode: ', error);
      })
    );
  }

  async getAcademy() {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection('academy').ref.where('campaign', '==', 'conectavantagens').get().then((results) => {
        let trainings = [];
        results.forEach((training) => {          
          trainings.push(training.data());          
        })
        trainings.sort(function (a, b) {
          if (new Date(a.id) < new Date(b.id)) {
            return 1;
          }
          if (new Date(a.id) > new Date(b.id)) {
            return -1;
          }
          return 0;
        })        
        resolve(trainings);
        // resolve(results);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  async setHashtag(hash: any, post: any) {
    return await new Promise((resolve, reject) => {
      hash = hash.replace(/(\r\n|\n|\r)/gm, "").split(' ').join('');
      if (hash != '') {
        let posts = []
        this.angularFirestore.collection('hashtags').doc(hash.toString()).ref.get().then((doc) => {
          if (doc.data()) {
            posts = doc.data().posts;
          }
          posts.push(post)
          this.angularFirestore.collection('hashtags').doc(hash.toString()).set({
            "posts": posts
          }).then(() => {
            resolve({ created: true });
          }).catch((err) => {
            reject(err);
          });
        }).catch((err) => {
          reject(err);
        });
      }
    });
  }

  async getPromoBanners() {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection('cvBanners').doc('bannersFiqueAtento').ref.get().then((results) => {
        resolve(results);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  async getAcademyId(id) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection('academy').doc(id).ref.get().then((quiz) => {
        resolve(quiz);
      }).catch((err) => {
        reject(err);
      })
    })
  }

  verifyVersion() {
    this.angularFirestore.collection('versioningConectaVantagens').doc('version').ref.get().then((version) => {
      if (environment.version != version.data().version) {
        setTimeout(() => {
          window.location.reload(true)
        }, 4000)
      }
    }).catch((err) => {
      alert("Erro ao verificar versão")
    })
  }

  async updateLikeInAcademyCard(academyID, likeList) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection(`academy`).doc(academyID).update({ likes: likeList })
        .then((commentResult: any) => {
          resolve(commentResult);
        }).catch((err) => {
          reject(err);
        });
    });
  }

  async sendContact(contact) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection('contact').add(contact).then((contactResult) => {
        resolve(contactResult);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  async getHomeCards() {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection('homeCardsConectaVantagens').doc('cards').ref.get().then((cardsResult) => {
        resolve(cardsResult);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  autocompleteUser(user) {
    return new Promise((resolve, reject) => {
      this.angularFirestore.collection('sellers').ref
        .where('nickname', '>=', user)
        .orderBy('nickname', 'asc')
        .limit(7)
        .get()
        .then((results) => {
          let items = [];
          results.forEach((i) => {
            let item = i.data();
            item.sellerId = i.id;
            items.push(item);
          });
          resolve(items);
        })
    })
  }

  updatePost(p) {
    return new Promise((resolve, reject) => {
      this.angularFirestore.collection('timeline').doc(p.id).update(p.data)
        .then(() => {
          resolve();
        })
    })
  }

  updatePostFixed(p) {
    return new Promise((resolve, reject) => {
      this.angularFirestore.collection('timelineFixedPosts').doc(p.id).update(p.data)
        .then(() => {
          resolve();
        })
    })
  }

  updateComment(p, c, comment) {
    return new Promise((resolve, reject) => {
      this.angularFirestore.collection('timeline').doc(p).collection('comments').doc(c).update(comment)
        .then(() => {
          resolve();
        })
    })
  }

  updateCommentFixed(p, c, comment) {
    return new Promise((resolve, reject) => {
      this.angularFirestore.collection('timelineFixedPosts').doc(p).collection('comments').doc(c).update(comment)
        .then(() => {
          resolve();
        })
    })
  }

  updateSubComment(p, c, s, comment) {
    return new Promise((resolve, reject) => {
      this.angularFirestore.collection('timeline').doc(p).collection('comments').doc(c).collection('subcomments').doc(s).update(comment)
        .then(() => {
          resolve();
        })
    })
  }

  updateSubCommentFixed(p, c, s, comment) {
    return new Promise((resolve, reject) => {
      this.angularFirestore.collection('timelineFixedPosts').doc(p).collection('comments').doc(c).collection('subcomments').doc(s).update(comment)
        .then(() => {
          resolve();
        })
    })
  }

  deleteComment(p, c) {
    return new Promise((resolve, reject) => {
      this.angularFirestore.collection('timeline').doc(p).collection('comments').doc(c).delete()
        .then(() => {
          resolve();
        })
    })
  }

  deleteCommentFixed(p, c) {
    return new Promise((resolve, reject) => {
      this.angularFirestore.collection('timelineFixedPosts').doc(p).collection('comments').doc(c).delete()
        .then(() => {
          resolve();
        })
    })
  }

  deleteSubCommentFixed(p, c, s) {
    return new Promise((resolve, reject) => {
      this.angularFirestore.collection('timelineFixedPosts').doc(p).collection('comments').doc(c).collection('subcomments').doc(s).delete()
        .then(() => {
          resolve();
        })
    })
  }

  deleteSubComment(p, c, s) {
    return new Promise((resolve, reject) => {
      this.angularFirestore.collection('timeline').doc(p).collection('comments').doc(c).collection('subcomments').doc(s).delete()
        .then(() => {
          resolve();
        })
    })
  }

  archivePost(p) {
    return new Promise((resolve, reject) => {
      this.angularFirestore.collection('timeline-archives').doc(p.id).set(p.data)
        .then(() => {
          this.angularFirestore.collection('timeline').doc(p.id).delete()
            .then(() => {
              resolve();
            })
        })
    })
  }

  async getPosts() {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection('timeline').ref
        .orderBy('createdAt', 'desc')
        .startAfter(this.lastDatePost)
        .limit(3)
        .get().then((timelineResult) => {
          let posts = [];
          timelineResult.forEach((post: any) => {
            posts.push({ id: post.id, data: post.data() });
          });
          if (posts.length > 0) {
            this.lastDatePost = posts[posts.length - 1].data.createdAt;
          }
          resolve(posts);
        }).catch((err) => {
          reject(err);
        });
    });
  }

  async getPost(date) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection('timeline').ref
        .where('createdAt', '==', date)
        .get().then((timelineResult) => {
          let posts = [];
          timelineResult.forEach((post: any) => {
            posts.push({ id: post.id, data: post.data() });
          });
          if (posts.length > 0) {
            this.lastDatePost = posts[posts.length - 1].data.createdAt;
          }
          resolve(posts);
        }).catch((err) => {
          reject(err);
        });
    });
  }

  async getPostById(postID) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection('timeline').doc(postID).ref.get()
        .then((postResult) => {
          if (postResult.exists) {
            let post = [];
            post.push({ id: postResult.id, data: postResult.data() });
            resolve(post);
          }
          else {
            this.angularFirestore.collection('timelineFixedPosts').doc(postID).ref.get()
              .then((postResult2) => {
                let post = [];
                post.push({ id: postResult2.id, data: postResult2.data(), fixed: true });
                resolve(post);
              })
          }
        })
        .catch((err) => reject(err))
    });
  }

  async getPostForHashtag(postID) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection('timeline').doc(postID).ref.get()
        .then((postResult) => {
          if (postResult.exists) {
            resolve({ id: postResult.id, data: postResult.data() });
          }
        })
        .catch((err) => reject(err))
    });
  }

  async createPost(post) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection('timeline').add(post).then((postResult) => {
        resolve(postResult);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  getFixedPost() {
    return new Promise((resolve, reject) => {
      this.angularFirestore.collection(`timelineFixedPosts`).ref.get().then((timelineResult) => {
        let array = [];
        timelineResult.forEach((e) => {
          let item = e.data();
          item.id = e.id;
          array.push(item);
        })
        resolve(array);
      }).catch((err) => {
        reject(err);
      });
    })
  }

  async addImageInPost(postID, image) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection(`timeline`).doc(postID).update({ image: image }).then((timelineResult: any) => {
        resolve(timelineResult);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  async createNotification(notification) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection('personalNotifications').add(notification).then((resp) => {
        resolve(resp);
      })
        .catch((err) => {
          reject(err);
        });
    })
  }

  markUser(item) {
    this.angularFirestore.collection('personalNotifications').add(item);
  }

  async createCommentPost(comment, postID) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection(`timeline/${postID}/comments`).add(comment).then((commentResult) => {
        resolve(commentResult);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  async createCommentPostFixed(comment, postID) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection(`timelineFixedPosts/${postID}/comments`).add(comment).then((commentResult) => {
        resolve(commentResult);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  async createSubCommentPost(comment, postID, commentID) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection(`timeline/${postID}/comments/${commentID}/subcomments`).add(comment).then((subCommentResult) => {
        resolve(subCommentResult);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  async createSubCommentPostFixed(comment, postID, commentID) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection(`timelineFixedPosts/${postID}/comments/${commentID}/subcomments`).add(comment).then((subCommentResult) => {
        resolve(subCommentResult);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  async getCommentsByPostIDFixed(postID) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection(`timelineFixedPosts/${postID}/comments`).ref.orderBy('createdAt', 'desc').get().then((commentResult) => {
        let comments = [];
        commentResult.forEach((comment: any) => {
          comments.push({ id: comment.id, data: comment.data() });
        });
        resolve(comments);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  async getCommentsByPostID(postID) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection(`timeline/${postID}/comments`).ref.orderBy('createdAt', 'desc').get().then((commentResult) => {
        let comments = [];
        commentResult.forEach((comment: any) => {
          comments.push({ id: comment.id, data: comment.data() });
        });
        resolve(comments);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  async getSubCommentsByPostIDFixed(postID, commentID) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection(`timelineFixedPosts/${postID}/comments/${commentID}/subcomments`).ref.orderBy('createdAt', 'desc').get().then((subCommentsResult) => {
        let comments = [];
        subCommentsResult.forEach((comment: any) => {
          comments.push({ id: comment.id, data: comment.data() });
        });
        resolve(comments);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  async getSubCommentsByPostID(postID, commentID) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection(`timeline/${postID}/comments/${commentID}/subcomments`).ref.orderBy('createdAt', 'desc').get().then((subCommentsResult) => {
        let comments = [];
        subCommentsResult.forEach((comment: any) => {
          comments.push({ id: comment.id, data: comment.data() });
        });
        resolve(comments);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  async getLikesByPostID(postID) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection(`timeline/${postID}/comments`).ref.get().then((commentResult) => {
        let likes = [];
        commentResult.forEach((like: any) => {
          likes.push({ id: like.id });
        });
        resolve(likes);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  async updateLikeInComment(commentID, postID, likeList) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection(`timeline/${postID}/comments`).doc(commentID).update({ likes: likeList })
        .then((commentResult: any) => {
          resolve(commentResult);
        }).catch((err) => {
          reject(err);
        });
    });
  }

  async updateLikeInCommentFixed(commentID, postID, likeList) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection(`timelineFixedPosts/${postID}/comments`).doc(commentID).update({ likes: likeList })
        .then((commentResult: any) => {
          resolve(commentResult);
        }).catch((err) => {
          reject(err);
        });
    });
  }

  async updateLikeInPost(postID, likeList) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection(`timeline`).doc(postID).update({ likes: likeList }).then((commentResult: any) => {
        resolve(commentResult);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  async updateLikeInPostFixed(postID, likeList) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection(`timelineFixedPosts`).doc(postID).update({ likes: likeList }).then((commentResult: any) => {
        resolve(commentResult);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  async updateLikeInSubComment(subCommentID, commentID, postID, likeList) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection(`timeline/${postID}/comments/${commentID}/subcomments`)
        .doc(subCommentID).update({ likes: likeList })
        .then((commentResult: any) => {
          resolve(commentResult);
        }).catch((err) => {
          reject(err);
        });
    });
  }

  async updateLikeInSubCommentFixed(subCommentID, commentID, postID, likeList) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection(`timelineFixedPosts/${postID}/comments/${commentID}/subcomments`)
        .doc(subCommentID).update({ likes: likeList })
        .then((commentResult: any) => {
          resolve(commentResult);
        }).catch((err) => {
          reject(err);
        });
    });
  }

  async getMissions() {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection(`missions`).ref.get().then((missionsResult) => {
        let missions = [];
        missionsResult.forEach((mission) => {
          if (mission.data().platform == 'CV') {
            missions.push({ id: mission.id, data: mission.data() });
          }
        })
        missions.sort(function (a, b) {
          if (new Date(a.data.createdAt) < new Date(b.data.createdAt)) {
            return 1;
          }
          if (new Date(a.data.createdAt) > new Date(b.data.createdAt)) {
            return -1;
          }
          return 0;
        })
        resolve(missions);
      }).catch((err) => {
        reject(err);
      })
    })
  }

  async updateMissionUser(user, cpf){
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection('sellers').doc(cpf).update(user).then((result) => {
        resolve();
      }).catch((err) => {
        reject(err);
      });
    });
  }

  async updateAcademyUser(user, cpf){
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection('sellers').doc(cpf).update(user).then((result) => {
        resolve(result);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  async sendEmailMission(nameFrom, emailFrom, data) {
    return await new Promise((resolve, reject) => {
      let emailObject = {
        templateId: data.template,
        from: emailFrom,
        personalizations: [{
          to: {
            email: data.emailTo,
            name: data.nameTo,
          },
          data: {
            message: data.message,
            recipientName: data.nameTo,
            senderName: nameFrom,
            msg: data.message,
            img: data.image
          }
        }]
      }
      let httpOptions = {
        headers: new HttpHeaders({
          'Authorization': environment.secToken,
        })
      }
      this.http.post('https://us-central1-iloyalty-prd.cloudfunctions.net/api/email/sendtemplate', emailObject, httpOptions).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  async sendEmailSignupConcluded(emailFrom, data) {
    return await new Promise((resolve, reject) => {
      let emailObject = {
        templateId: 'd-ef86ed1f71614bb7a95084ce894792f9',
        from: emailFrom,
        personalizations: [{
          to: {
            email: data.emailTo,
            name: data.nameTo,
          },
          data: {
            name: data.name,
            cpf: data.cpf,
            password: data.password,
          }
        }]
      }
      let httpOptions = {
        headers: new HttpHeaders({
          'Authorization': environment.secToken,
        })
      }
      this.http.post('https://us-central1-iloyalty-prd.cloudfunctions.net/api/email/sendtemplate', emailObject, httpOptions).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  async getStamps() {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection(`stamps`).ref.get().then((stampsResult) => {
        let stamps = [];
        stampsResult.forEach((stamp) => {
          stamps.push({ id: stamp.id, data: stamp.data() })
        })
        resolve(stamps)
      }).catch((err) => {
        reject(err);
      })
    })
  }

  async getStampByName(name) {
    return await new Promise((resolve, reject) => {
      if (name) {
        this.angularFirestore.collection(`stamps`).ref.where('name', '==', name).get().then((stampsResult) => {
          let stamps = [];
          stampsResult.forEach((stamp) => {
            stamps.push({ id: stamp.id, conquestedAt: new Date().toISOString() })
          })
          resolve(stamps);
        }).catch((err) => {
          reject(err);
        })
      } else {
        resolve([]);
      }
    })
  }

  async createMessageMission(data, missionID) {
    let id = new Date().getTime().toString() + "" + (Math.floor(100000 + Math.random() * 900000)).toString();    
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection(`missions/${missionID}/messages`).ref.doc(id).set(data).then((res) => {        
        resolve(id);
      }).catch((err) => {
        reject(err);
      })
    })
  }

  async addImageMessageMission(image, missionID, messageID) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection(`missions/${missionID}/messages`).doc(messageID).ref.update({ image: image }).then((res: any) => {
        resolve(res);
      }).catch((err) => {
        reject(err)
      })
    })
  }

  async addCertificate(certificate) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection(`certificates`).add(certificate).then((result) => {
        resolve(result);
      }).catch((err) => {
        reject(err);
      })
    })
  }

  getUserByCpf(cpf) {
    return new Promise((resolve, reject) => {
      this.angularFirestore.collection('sellers').doc(cpf).ref.get()
        .then((r) => {
          resolve(r.data());
        })
    })
  }

  async verifyExistsCertificate(code: string) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection(`certificates`).ref.where('code', '==', code).get().then((result) => {
        if (result.empty) {
          resolve(false);
        } else {
          resolve(true);
        }
      }).catch((err) => {
        reject(err);
      });
    });
  }

  async getSellerManager(cnpj: string) {
    return await new Promise<any>((resolve, reject) => {
      this.angularFirestore.collection(`managers`).ref.where('owner', '==', cnpj).get().then(manager => {
        if (manager.empty) {
          resolve(null);
        } else {
          resolve(manager.docs[0].data());
        }
      }).catch((err) => {
        reject(err);
      });
    });
  }

  async getSystemAlerts() {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection(`systemAlerts`).ref.orderBy('createdAt', 'desc').limit(5).get().then((result) => {
        let alerts = [];
        result.forEach((alert) => {
          alerts.push(alert.data());
        })
        resolve(alerts);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  onlyNumbers(data: string) {
    return data.replace(/[^0-9]/g, '');
  }

  markAsReadPersonal(id) {
    return new Promise((resolve, reject) => {
      this.angularFirestore.firestore.collection('personalNotifications').doc(id)
        .update({ visited: true })
        .then(() => {
          resolve()
        })
    })
  }

  markAsReadGeneral(id) {
    return new Promise((resolve, reject) => {
      this.angularFirestore.firestore.collection('generalNotifications').doc(id)
        .update({ visited: true })
        .then(() => {
          resolve()
        })
    })
  }

  async getPersonalNotifications(cpf) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection(`personalNotifications`).ref
        .where('cpf', '==', cpf)
        .orderBy('createdAt', 'desc').limit(4).get().then((result) => {
          let notifications = [];
          let notVisiteds = [];
          result.forEach((notification) => {
            let not = notification.data();
            if (!not.visited) {
              notVisiteds.push(not);
            }
            not.id = notification.id;
            notifications.push(not);
          })
          let obj = {
            notifications: notifications,
            notVisiteds: notVisiteds
          };
          resolve(obj);
        }).catch((err) => {
          reject(err);
        });
    });
  }

  async getGeneralNotifications() {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection(`generalNotifications`).ref.get().then((result) => {
        let notifications = [];
        let notVisiteds = [];
        result.forEach((notification) => {
          let not = notification.data();
          if (!not.visited) {
            notVisiteds.push(not);
          }
          not.id = notification.id;
          notifications.push(not);
        })
        let obj = {
          notifications: notifications,
          notVisiteds: notVisiteds
        };
        resolve(obj);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  async getTargetThroughCNPJ(cnpj, cpf) {
    return await new Promise(
      async (resolve, reject) => {
        if (cnpj) {
          await this.angularFirestore.collection(`targets`).ref.where('cnpj', '==', cnpj.toString()).get().then((querysnapshot) => {
                if (querysnapshot.empty) {
                  return reject({ result: false, empty: querysnapshot.empty });
                }
                let result = querysnapshot.docs[0];
                resolve(result.data());
                return;
              }).catch((err) => {
              console.log(`firebaseService: getTargetThroughCNPJ: error:`, err);
              reject({ result: false, err });
              return;
            });
        }
        else {
          await this.angularFirestore.collection(`sellers`).doc(cpf.toString()).ref.get().then(async (resultSeller) => {
                let userCnpj = resultSeller.data().cnpj;
                if (!userCnpj) { userCnpj = resultSeller.data().optical.cnpj };
                await this.angularFirestore.collection(`targets`).doc(userCnpj.toString()).ref.get().then((result) => {
                    resolve(result.data());
                  }).catch((err) => {
                    reject(err);
                  });
              }).catch((err) => {
              reject(err);
            });
        }
      });
  }

  async getOtica(cpf) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection(`sellers`).doc(cpf.toString()).ref.get().then((result) => {
        resolve(result.data());
      }).catch((err) => {
        reject(err);
      });
    });
  }

  async getPersonalNotificationsPost(cpf) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection(`markUser`).ref.where('sellerId', '==', cpf)
        .orderBy('createdAt', 'desc').get().then((result) => {
          let notifications = [];
          result.forEach((notification) => {
            let not = notification.data();
            not.id = notification.id;
            notifications.push(not);
          })
          resolve(notifications);
        })
    })
  }

  async getNotifications() {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection(`notifications`).ref.get().then((result) => {
        let notifications = [];
        let notVisiteds = [];
        result.forEach((notification) => {
          let not = notification.data();
          if (not.userType == 'Vendedor') {
            if (!not.visited) {
              notVisiteds.push(not);
            }
            notifications.push(not);
          }
        })
        let obj = {
          notifications: notifications,
          notVisiteds: notVisiteds
        };
        resolve(obj);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  async checkIfUserExistsInVE(cpf) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection('managers').doc(cpf.toString()).ref.get().then((result) => {
        resolve(result.data());
      }).catch((err) => {
        reject(err);
      });
    })
  }

  async checkIfUserExistsInCV(cpf) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection('sellers').doc(cpf.toString()).ref.get().then((result) => {
        resolve(result.data());
      }).catch((err) => {
        reject(err);
      });
    })
  }

  async checkIfUserHaveCNPJ(cnpj) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection('owners').doc(cnpj).ref.get().then((company) => {
        resolve(company.data());
      }).catch((err) => {
        reject(err);
      })
    })
  }

  async checkIfUserHaveCNPJInMembers(cnpj: String) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection('member').doc(cnpj.toString()).ref.get().then((company) => {
        resolve(company.data());
      }).catch((err) => {
        reject(err);
      })
    })
  }

  async getPromocaoTransitions() {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection("promocoes").doc("insercaoTransitions").ref.get().then((result) => {
        resolve(result.data());
      }).catch((err) => {
        reject(err);
      })
    })
  }

  async updateContador(dia: any) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection("promocoes").doc("insercaoTransitions").update({
        "contador": 0,
        "dataContador": dia
      }).then(res => {
        resolve();
      }).catch((err) => {
        reject(err);
      })
    })
  }

  async insertWinnerPromotion(cpf, code) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection("promocoes").doc("insercaoTransitions").collection("ganhadores").add({
        "user": cpf,
        "data": new Date(),
        "token": code
      }).then(res => {
        resolve(res);
      }).catch((err) => {
        reject(err);
      })
    })
  }

  async winningUser(cpf: string, code: string) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection("promocoes").doc("insercaoTransitions").collection("ganhadores").add({
        "user": cpf,
        "data": new Date(),
        "token": code
      }).then(res => {
        resolve(true);
      }).catch((err) => {
        console.log('erro winningUser: ', err)
        reject(false);
      })
    })
  }

  async updatePromotionInputs(diaDeHoje: any, qtdUltimosGanhadores: any) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection("promocoes").doc("insercaoTransitions").update({
        "diaUltimoGanhador": diaDeHoje,
        "qtdUltimosGanhadores": qtdUltimosGanhadores + 1,
      }).then(res => {
        resolve(true);
      }).catch((err) => {
        console.log(err)
        reject(false);
      })
    })
  }

  async incrementContador(contador: any) {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection("promocoes").doc("insercaoTransitions").update({
        "contador": contador
      }).then(res => {
        resolve(true);
      }).catch((err) => {
        console.log(err)
        reject(false);
      })
    })
  }

  async verifyLabUsers(cpf: string){    
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection(`labUsers`).doc(cpf).ref.get().then((user) => {
        if(user.exists) { 
          resolve(true)
        }else{
          resolve(false)
        }        
      }).catch((err) => {
        reject(err);
      })
    })
  }

  async verifyMissionsAvailability(){    
    return await new Promise((resolve, reject) => {      
      this.angularFirestore.collection('partnersAvailability').doc('partnersAvailability').ref.get().then(d => {
        resolve(d.data().mission);
      }).catch(error => {
        console.log('erro ao verificar disponibilidade das missões.')  
        resolve(false);          
      });
    })
  }

  async verifyAcademyAvailability(){    
    return await new Promise((resolve, reject) => {      
      this.angularFirestore.collection('partnersAvailability').doc('partnersAvailability').ref.get().then(d => {
        resolve(d.data().academy);
      }).catch(error => {
        console.log('erro ao verificar disponibilidade dos treinamentos.', error)  
        resolve(false);
      });      
    })
  }

  async verifyInsertionAvailability(){    
    return await new Promise((resolve, reject) => {      
      this.angularFirestore.collection('partnersAvailability').doc('partnersAvailability').ref.get().then(d => {
        resolve(d.data().insercao);
      }).catch(error => {
        console.log('erro ao verificar disponibilidade dos treinamentos.', error)  
        resolve(false);
      });      
    })
  }

  async verifyTimelineAvailability(){    
    return await new Promise((resolve, reject) => {      
      this.angularFirestore.collection('partnersAvailability').doc('partnersAvailability').ref.get().then(d => {
        resolve(d.data().timeline);
      }).catch(error => {
        console.log('erro ao verificar disponibilidade dos treinamentos.', error)  
        resolve(false);
      });      
    })
  }

  async getAcademyBanner() {
    return await new Promise((resolve, reject) => {
      this.angularFirestore.collection("cvBanners").doc("bannersAcademy").ref.get().then((banner) => {
        if(banner.exists) { 
          resolve(banner.data())
        }     
      }).catch((err) => {
        reject(err);
      })
    })
  }
    
}
